const Footer = () => {
  return (
    <footer>
      <p>
        Coded by <a href="http://contelautaro.com.ar" className="footer-class">Lautaro Conte</a>.
      </p>
    </footer>
  );
};

export default Footer;
